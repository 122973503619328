<template>
    <div class="main-wrapper">
         <div class="title">
            <div class="titleData">{{headline.goods_name}}商品</div>
        </div>
        <el-scrollbar>
             <el-table :data="scriptListTable" class="customTable" style="width: 100%; flex: 1" height="1%">
                   <el-table-column prop="name" label="模板" width="400px">
                         <template slot-scope="scope">
                            模板{{ scope.$index+1 }}
                        </template>
                   </el-table-column>
                    <el-table-column  label="点击率" >
                        <template slot-scope="scope">
                             <el-input style="width: 40%;" maxlength="4" v-model="scope.row.click_rate" @blur="clickInputFocus(scope.row)" placeholder="0.01-10"></el-input>
                             <span style="padding-left: 10px;">%</span>
                        </template>
                    </el-table-column>
                    <el-table-column  label="转化率" >
                        <template slot-scope="scope">
                             <el-input style="width: 40%;"  maxlength="4" v-model="scope.row.change_rate" @blur="changeInputFocus(scope.row)" placeholder="0.01-10"></el-input>
                             <span style="padding-left: 10px;">%</span>
                        </template>
                    </el-table-column>
                     <el-table-column></el-table-column>
             </el-table>
        </el-scrollbar>
        <div class="footer-button">
            <el-button type="primary" @click="backTo">上一步</el-button>
            <el-button type="primary" @click="next">下一步</el-button>
        </div>
    </div>
</template>

<script>
import { validateGoodsTemplate } from "@/utils/apis";
export default {
    data(){
        return{
            scriptListTable:[
                {        
                    id:1,         
                    click_rate:"",
                    change_rate:""
                },
                 {
                    id:2,        
                    click_rate:"",
                    change_rate:""
                },
                 {
                    id:3, 
                   click_rate:"",
                    change_rate:""
                },
                 {
                     id:4, 
                    click_rate:"",
                    change_rate:""
                },
                 {
                     id:5, 
                  click_rate:"",
                    change_rate:""
                },
                 {
                    id:6, 
                 click_rate:"",
                    change_rate:""
                },
                 {
                      id:7, 
                    click_rate:"",
                    change_rate:""
                },
                 {
                      id:8, 
                  click_rate:"",
                    change_rate:""
                },
                 {
                      id:9, 
                    click_rate:"",
                    change_rate:""
                },
                 {
                      id:10, 
                   click_rate:"",
                    change_rate:""
                },
            ],
            headline:"",
            sum_id:''
        }
    },
    created(){
        this.headline = JSON.parse(localStorage.getItem("message"))
        this.$bus.$on("title", () => {        
            this.headline = JSON.parse(localStorage.getItem("message"))
        })
        this.editEstablish()  
    },
    methods:{
        clickInputFocus(row){
            let two = /^[-]?([1-9]{1}[0-9]{0,}(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|\.[0-9]{1,2})$/g;
            if(!two.test(row.click_rate)){
                this.$message.error("点击率最多保留两位小数");
            }else if (isNaN(row.click_rate) || row.click_rate>10){
                 this.$message.error("点击率范围在0.01-10之间");
                 row.click_rate = 10
            }else if (isNaN(row.click_rate) || row.click_rate==0){
                this.$message.error("点击率必须大于0");
            }

        },
        changeInputFocus(row){
            let two = /^[-]?([1-9]{1}[0-9]{0,}(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|\.[0-9]{1,2})$/g;
            if(!two.test(row.change_rate)){
                this.$message.error("转化率最多保留两位小数");
            }else if (isNaN(row.change_rate) || row.change_rate>10){
                 this.$message.error("转化率范围在0.01-10之间");
                 row.change_rate = 10
            }else if (isNaN(row.change_rate) || row.change_rate==0){
                this.$message.error("转化率必须大于0");
            }
        },
        editEstablish(){
            if (this.$route.query.id) { 
                this.sum_id = this.$route.query.id
                let eiitList = JSON.parse(localStorage.getItem("commodityeditData"))
                this.scriptListTable = eiitList.goods_template
            }
        },
           // 返回上一步
        backTo() {
            this.$router.push({
                path: "/liveStreaming/newComManagementCreate",query: { id: this.$route.query.id }    
            });
        },
       
        //下一步
        next(){
    
            if(this.scriptListTable[0].click_rate !=''&&this.scriptListTable[0].change_rate ==''){
                 this.$message.error("转化率不能为空");
                   return
            }else if(this.scriptListTable[0].click_rate ==''&&this.scriptListTable[0].change_rate !=''){
                this.$message.error("点击率不能为空");
                  return
            }else  if(this.scriptListTable[1].click_rate !=''&&this.scriptListTable[1].change_rate ==''){
                 this.$message.error("转化率不能为空1");
                 return
            }else if(this.scriptListTable[1].click_rate ==''&&this.scriptListTable[1].change_rate !=''){
                this.$message.error("点击率不能为空");
                  return
            } if(this.scriptListTable[2].click_rate !=''&&this.scriptListTable[2].change_rate ==''){
                 this.$message.error("转化率不能为空");
                   return
            }else if(this.scriptListTable[2].click_rate ==''&&this.scriptListTable[2].change_rate !=''){
                this.$message.error("点击率不能为空");
                  return
            } if(this.scriptListTable[3].click_rate !=''&&this.scriptListTable[3].change_rate ==''){
                 this.$message.error("转化率不能为空");
                   return
            }else if(this.scriptListTable[3].click_rate ==''&&this.scriptListTable[3].change_rate !=''){
                this.$message.error("点击率不能为空");
                  return
            } if(this.scriptListTable[4].click_rate !=''&&this.scriptListTable[4].change_rate ==''){
                 this.$message.error("转化率不能为空");
                   return
            }else if(this.scriptListTable[4].click_rate ==''&&this.scriptListTable[4].change_rate !=''){
                this.$message.error("点击率不能为空");
                  return
            } if(this.scriptListTable[5].click_rate !=''&&this.scriptListTable[5].change_rate ==''){
                 this.$message.error("转化率不能为空");
            }else if(this.scriptListTable[5].click_rate ==''&&this.scriptListTable[5].change_rate !=''){
                this.$message.error("点击率不能为空");
                  return
            } if(this.scriptListTable[6].click_rate !=''&&this.scriptListTable[6].change_rate ==''){
                 this.$message.error("转化率不能为空");
                   return
            }else if(this.scriptListTable[6].click_rate ==''&&this.scriptListTable[6].change_rate !=''){
                this.$message.error("点击率不能为空");
                  return
            } if(this.scriptListTable[7].click_rate !=''&&this.scriptListTable[7].change_rate ==''){
                 this.$message.error("转化率不能为空");
            }else if(this.scriptListTable[7].click_rate ==''&&this.scriptListTable[7].change_rate !=''){
                this.$message.error("点击率不能为空");
                  return
            } if(this.scriptListTable[8].click_rate !=''&&this.scriptListTable[8].change_rate ==''){
                 this.$message.error("转化率不能为空");
                   return
            }else if(this.scriptListTable[8].click_rate ==''&&this.scriptListTable[8].change_rate !=''){
                this.$message.error("点击率不能为空");
                 return
            } if(this.scriptListTable[9].click_rate !=''&&this.scriptListTable[9].change_rate ==''){
                 this.$message.error("转化率不能为空");
                  return
            }else if(this.scriptListTable[9].click_rate ==''&&this.scriptListTable[9].change_rate !=''){
                this.$message.error("点击率不能为空");
                 return
            }else{
                console.log('3333')
                let params = {
            
                goods_template:this.scriptListTable,
                };
                validateGoodsTemplate(params).then((res) => {
                if (res.code == 200) {
                    localStorage.setItem("templateDate", JSON.stringify(this.scriptListTable));
                    this.$router.push({
                        path: "/liveStreaming/newComManagementCoupon",query: { id: this.$route.query.id }
                    });
                }
                }).catch((err) => {
                console.error("err", err);
                });
            }
       

         
        },
        
    }
}
</script>
<style lang="scss" scoped>
 .main-wrapper {
        height: 82%;
        padding: 0 30px ;    
         .title{
            text-align: center;
            padding-bottom: 30px;
            .titleData{
                font-weight: bold; 
            }
        }
      .el-scrollbar {
          height: 100%;
        ::v-deep .el-scrollbar__wrap {
            height: 100%;
            overflow-x: hidden;    
        }
      }
      .footer-button{
          text-align: center;
          padding-top: 15px;
      } 
 }
</style>